<!-- 拍卖申请-标的信息 -->
<template>
  <div class="subjectMatterInfoBox">
    <div class="title">标的信息</div>

    <!-- form表单 -->
    <div class="formBox">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="140px"
        size="small"
        :rules="rules"
        inline-message
      >
        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >拍卖标题</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="拍卖标题" prop="applicationTitle">
          <el-input
            style="width: 60%"
            v-model="ruleForm.applicationTitle"
            placeholder="请输入拍卖标题"
          ></el-input>
        </el-form-item>
        <div class="tips">
          标题信息将展示在列表当中，简述拍卖标的重要信息即可。
        </div>

        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >标的材料</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="标的介绍" prop="introduction">
          <el-input
            style="width: 60%"
            v-model="ruleForm.introduction"
            type="textarea"
            placeholder="请输入拍卖介绍"
          ></el-input>
        </el-form-item>
        <div class="tips">
          介绍内容展示在拍卖详情中，须对标的的重要信息分条分类进行描述，但最好是对标的的整体描述，例如整体的生产时间，使用时间等信息。
        </div>
        <el-form-item label="标的物所在地址" prop="address">
          <el-cascader
            style="width: 60%"
            v-model="ruleForm.address"
            :options="areaList"
          />
          <el-input
            style="width: 60%"
            v-model="ruleForm.detailAddress"
            placeholder="标的详细地址"
          ></el-input>
        </el-form-item>
        <div class="tips">
          填写的标的物所在地，将用在预展览相关信息，以及运输相关信息中。
        </div>
        <el-form-item label="标的相关附件" prop="accessoryName">
          <el-upload
            style="width: 60%"
            v-model="ruleForm.accessoryName"
            ref="uploadRef"
            :limit="5"
            :headers="headers"
            accept=".doc,.docx, .xlsx,.xls, .pdf,.zip,.7z,.rar"
            :action="baseUrl + '/file/uploadFile'"
            :data="uploadFile"
            :on-success="handleFileSuccess"
            :on-remove="handleRemoveFile"
            :file-list="ruleForm.accessoryName"
            :on-exceed="handleExceed"
            :on-change="handleFileChange"
            :before-upload="beforeUploadFile"
          >
            <el-button type="primary">点击上传</el-button>
            <span class="tips tip1"
              >最多可上传5份文件，支持word、excel、pdf、zip、7z、rar等格式</span
            >
          </el-upload>
        </el-form-item>
        <div class="tips">
          当标的存在多个时（例如多辆车，多个电池包等），可按照编号等一行一个进行介绍。用户可通过下载附件查看详细介绍信息。
        </div>
        <el-form-item label="标的相关图片" prop="pictureName">
          <el-upload
            v-model="ruleForm.pictureName"
            class="upload-demo"
            :headers="headers"
            :limit="10"
            drag
            accept=".png, .jpg, .jpeg"
            :action="baseUrl + '/file/uploadFile'"
            multiple
            :data="uploadImage"
            :file-list="ruleForm.pictureName"
            :on-remove="handlePictureRemove"
            :on-exceed="handlePictureExceed"
            :on-change="handlePictureChange"
            :before-upload="beforeUploadImage"
            list-type="picture"
          >
            <em class="el-icon-upload"></em>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              最多可上传10张照片，支持png,jpg,jpeg
            </div>
          </el-upload>
        </el-form-item>
        <div class="tips">
          当标的存在多个时（例如多辆车，多个电池包等），可按照编号等一行一个进行介绍。用户可通过下载附件查看详细介绍信息。
        </div>

        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >报名条件</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="保证金" prop="deposit">
          <el-input
            style="width: 60%"
            v-model="ruleForm.deposit"
            placeholder="请输入保证金"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <div class="tips">
          保证金是拍卖时向竞买人收取的保障性费用，主要用于限制竞拍人理性出价，诚信履约。保证金应能够覆盖拍卖后、合同签署前毁约导致的成本，请综合考虑后存储，人力投入等条件后填写，工作人员会与您再次确认。
        </div>
        <el-form-item label="参拍人资质">
          <el-checkbox
            true-label="Y"
            false-label="N"
            v-model="ruleForm.isQualify"
            >废危品经营许可证</el-checkbox
          >
        </el-form-item>
        <div class="tips">
          若勾选对应资质，报名用户须先经过相应资质校验方可报名参与竞拍
        </div>
        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >预展安排</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="是否支持预展/勘验" prop="isPreview">
          <el-select style="width: 60% !important" v-model="ruleForm.isPreview">
            <el-option value="Y" label="支持预展/勘验"></el-option>
            <el-option value="N" label="不支持预展/勘验"></el-option>
          </el-select>
        </el-form-item>
        <div class="tips"></div>
        <el-form-item
          label="预展时间"
          prop="previewTime"
          v-if="ruleForm.isPreview !== 'N'"
        >
          <el-date-picker
            style="width: 60% !important"
            v-model="ruleForm.previewTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <div class="tips" v-if="ruleForm.isPreview !== 'N'">
          预展览时间须在公告发布后，拍卖开始前进行。因此请选择在此期间的时间段。
        </div>
        <el-form-item
          label="预展联系人"
          prop="previewContact"
          v-if="ruleForm.isPreview !== 'N'"
        >
          <el-input
            style="width: 60%"
            v-model="ruleForm.previewContact"
            placeholder="请输入预展联系人"
          ></el-input>
        </el-form-item>
        <div class="tips" v-if="ruleForm.isPreview !== 'N'">
          请预留预展览人的称呼，方便在拍卖报名页面中展示。
        </div>
        <el-form-item
          label="预展联系电话"
          prop="previewContactNumber"
          v-if="ruleForm.isPreview !== 'N'"
        >
          <el-input
            style="width: 60%"
            v-model="ruleForm.previewContactNumber"
            placeholder="请输入预展联系电话"
          ></el-input>
        </el-form-item>
        <div class="tips" v-if="ruleForm.isPreview !== 'N'">
          请填写预展览人的联系电话，联系电话将公布在拍卖详情当中。
        </div>

        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >拍卖方案</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="出价方式" prop="quoting">
          <el-radio-group v-model="ruleForm.quoting">
            <el-radio label="0">按商品总价</el-radio>
            <el-radio label="1">按商品单价</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tips">
          说明：若勾选按总价出价，则以实际描述商品进行交易，报价基于总价进行加价及报价。若勾选按单价出价，则用户成交以单价方式结算，且您须补充计价单位和本次成交数量。
        </div>
        <el-form-item
          v-if="ruleForm.quoting == 1"
          label="报价单位"
          prop="quotationUnit"
        >
          <el-input
            style="width: 60%"
            v-model="ruleForm.quotationUnit"
            placeholder="请输入报价单位（例：个、吨）"
            maxlength="8"
          >
            <template slot="prepend">元 /</template>
          </el-input>
        </el-form-item>
        <div class="tips" v-if="ruleForm.quoting == 1">
          说明：填写商品交易计价单位，成交后合同执行说明中，也将对交易金额进行内容说明。计价单位例如“千克”、“吨”、“安时”、“千瓦时”等。
        </div>
        <el-form-item
          v-if="ruleForm.quoting == 1"
          style="width: 66%;position: relative;"
          label="交易数量"
          prop="transactionQuantity"
        >
          <el-input-number
           style="width: 100%;"
            v-model="ruleForm.transactionQuantity"
            :step="1"
            :min="1"
            step-strictly
            placeholder="请输入交易数量"
          />
          <span style="position: absolute;index:99;right:50px;color: #999;">{{ ruleForm.quotationUnit }}</span>
        </el-form-item>
        <div class="tips" v-if="ruleForm.quoting == 1">
          说明：填写本次拍卖出售商品的数量，数量以您在上方填写的计价单位为标准单位，数量填写请填写正整数。
        </div>
        <el-form-item label="起拍价" prop="startingPrice">
          <el-input
            style="width: 60%"
            v-model="ruleForm.startingPrice"
            placeholder="请输入起拍价"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <div class="tips">
          填写您期望的标的物起拍价，起拍价请填写所有标的总和的起拍价。如需分批拍卖，策拍人会与您联系协商分批的起拍价格。起拍价越低，拍卖项目越具备吸引力，但同时成交价格低的风险也会增加。
        </div>

        <el-form-item label="保留价" prop="isReservePrice">
          <el-radio-group v-model="ruleForm.isReservePrice">
            <el-radio label="N">不设置保留价</el-radio>
            <el-radio label="Y">设置保留价</el-radio>
          </el-radio-group>
          <div>
            <el-input
              style="width: 60%"
              :disabled="ruleForm.isReservePrice == 'N'"
              v-model="ruleForm.reservePrice"
              placeholder="请输入保留价"
            >
              <template v-slot:suffix>
                <span>元</span>
              </template>
            </el-input>
          </div>
        </el-form-item>
        <div class="tips">
          说明：设置保留价后，竞拍结束时，最终出价若为超过保留价，则本厂定义为流拍。保留价的设置为了保障拍卖方能够以不低于最低心里价位的价格出售。同时流拍又会造成后续拍卖参拍者的参拍医院降低，请谨慎考虑开启保留价。
        </div>
        <el-form-item label="拍卖时间" prop="auctionTime">
          <el-date-picker
            style="width: 60% !important"
            v-model="ruleForm.auctionTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <div class="tips">
          拍卖时间为实际开始拍卖活动的时间，您可以选择对您适合的连续多日作为备选。但请注意，拍卖时间最早可选7工作日之后的日期，因拍卖须公告公示不小于7工作日以及完成现场预展览工作。拍卖最晚时间可选30天内的日期。
        </div>
        <div class="bigTitsBox">
          <div class="bigTits" style="top: -10px">
            <el-divider content-position="left" border-style="dashed">
              <span style="font-size: 20px; color: #ccc; font-weight: 600"
                >其他说明</span
              >
            </el-divider>
          </div>
        </div>
        <el-form-item label="其他补充要求" prop="requirements">
          <el-input
            style="width: 60%"
            type="textarea"
            v-model="ruleForm.requirements"
            placeholder="请输入其他补充要求"
          ></el-input>
        </el-form-item>
        <div class="tips">
          请填写您对本次拍卖的其他要求，例如：标的拆分要求，参拍企业要求等。
        </div>
        <el-form-item label="其他说明附件" prop="otherNotesFiles">
          <el-upload
            style="width: 60%"
            v-model="ruleForm.otherNotesFiles"
            ref="uploadRef"
            :limit="5"
            :headers="headers"
            accept=".doc,.docx, .xlsx,.xls, .pdf,.zip,.7z,.rar"
            :action="baseUrl + '/file/uploadFile'"
            :data="uploadFileQT"
            :on-success="handleFileSuccess"
            :on-remove="handleRemoveFileQT"
            :file-list="ruleForm.otherNotesFiles"
            :on-exceed="handleExceedQT"
            :on-change="handleFileChangeQT"
            :before-upload="beforeUploadFileQT"
          >
            <el-button type="primary">点击上传</el-button>
            <span class="tips tip1"
              >最多可上传5份文件，支持word、excel、pdf、zip、7z、rar等格式</span
            >
          </el-upload>
        </el-form-item>
        <div class="tips">
          说明：当需要上传以文件形式上传说明文件时，可在此处拖动附件进行上传说明。管理人员会依据要求对拍卖活动进行策划设计
        </div>
        <div class="btnStyle">
          <el-button type="success" plain @click="toUp()">上一步</el-button>
          <el-button type="success" @click="onSubmit">提交申请</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/token";
import { deleteFile, insertAuction } from "@/api/workSpace";
export default {
  data() {
    return {
      uploadFile: {},
      uploadFileQT: {},
      uploadImage: {},
      fileName: "",
      headers: { Authorization: "Bearer " + getToken() },
      baseUrl: "/api",
      ruleForm: {
        country: [],
        previewTime: [],
        accessoryName: [],
        otherNotesFiles: [],
        pictureName: [],
        // auctionTime: "",
        // previewTime:"",
      },
      areaList: [],
      rules: {
        applicationTitle: [
          { required: true, message: "请输入拍卖标题", trigger: "change" },
        ],
        introduction: [
          { required: true, message: "请输入标的介绍", trigger: "change" },
        ],
        accessoryName: [
          { required: true, message: "请上传标的相关文件", trigger: "change" },
        ],
        pictureName: [
          { required: true, message: "请上传标的相关图片", trigger: "change" },
        ],
        quotationUnit: [
          { required: true, message: "请填写出价单位", trigger: "change" },
        ],
        transactionQuantity: [
          { required: true, message: "请填写交易数量", trigger: "change" },
        ],
        address: [
          {
            required: true,
            message: "请选择标的物所在地址",
            trigger: "change",
          },
        ],
        isReservePrice: [
          {
            required: true,
            message: "请选择是否设置保留价",
            trigger: "change",
          },
        ],
        quoting: [
          {
            required: true,
            message: "请选择出价方式",
            trigger: "change",
          },
        ],
        startingPrice: [
          {
            required: true,
            message: "请填写起拍价",
            trigger: "change",
          },
        ],
        deposit: [
          {
            required: true,
            message: "请填写保证金",
            trigger: "change",
          },
        ],
        auctionTime: [
          {
            required: true,
            message: "请选择拍卖时间",
            trigger: "change",
          },
        ],
        isPreview: [
          {
            required: true,
            message: "请选择是否支持预展/勘验",
            trigger: "change",
          },
        ],
        previewTime: [
          {
            required: true,
            message: "请选择预展时间",
            trigger: "change",
          },
        ],
        previewContact: [
          {
            required: true,
            message: "请填写预展联系人",
            trigger: "change",
          },
        ],
        previewContactNumber: [
          {
            required: true,
            message: "请填写预展联系电话",
            trigger: "change",
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
      },

      baseInfoData: {},

      pickerOptions: {
        // 禁用今天之前的日期
        disabledDate: function disabledDate(time) {
          // 获取今天的时间戳
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const todayTimestamp = today.getTime();

          // 获取传入日期的时间戳
          const pickedTimestamp = time.getTime();

          // 如果传入日期的时间戳小于今天的时间戳，禁用该日期
          return pickedTimestamp < todayTimestamp;
        },
      },
    };
  },

  watch: {
    "ruleForm.accessoryName": {
      handler(newVal) {
        if (newVal.length) {
          this.$refs["ruleForm"].clearValidate(["accessoryName"]);
        }
      },
      deep: true,
    },
    "ruleForm.pictureName": {
      handler(newVal) {
        if (newVal.length) {
          this.$refs["ruleForm"].clearValidate(["pictureName"]);
        }
      },
      deep: true,
    },
  },
  created() {
    this.areaList = this.$store.state.area.areaList;
  },
  mounted() {
    // 接收基础信息数据
    this.$EventBus.$on("baseInfoData", (data) => {
      this.baseInfoData = data;
    });
  },
  methods: {
    handleExceed(file, fileList) {
      this.$message({
        message: "最多只能上传五个文件！",
        type: "warning",
      });
    },
    handleExceedQT(file, fileList) {
      this.$message({
        message: "最多只能上传五个文件！",
        type: "warning",
      });
    },

    // 文件change
    handleFileChange(file, fileList) {
      this.ruleForm.accessoryName = fileList;
    },
    // 文件change
    handleFileChangeQT(file, fileList) {
      this.ruleForm.otherNotesFiles = fileList;
    },
    // 上传文件之前的函数
    beforeUploadFile(file) {
      // .doc,.docx, .xlsx,.xls, .pdf,.zip,.7z
      // 限制类型
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      const extension =
        testmsg === "doc" ||
        testmsg === "docx" ||
        testmsg === "xlsx" ||
        testmsg === "xls" ||
        testmsg === "pdf" ||
        testmsg === "zip" ||
        testmsg === "7z" ||
        testmsg === "rar";
      if (!extension) {
        this.$message({
          type: "warning",
          message: "文件上传格式不正确！",
        });
        return extension;
      } else if (file.size > maxSize) {
        this.$message.warning(`文件 ${file.name} 大小超过限制（10MB）`);
        return false; // 阻止文件上传
      } else {
        this.uploadFile.objectName = "/apply/application/" + file.name;
      }
    },
    // 上传文件之前的函数
    beforeUploadFileQT(file) {
      // .doc,.docx, .xlsx,.xls, .pdf,.zip,.7z
      // 限制类型
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
      const extension =
        testmsg === "doc" ||
        testmsg === "docx" ||
        testmsg === "xlsx" ||
        testmsg === "xls" ||
        testmsg === "pdf" ||
        testmsg === "zip" ||
        testmsg === "7z" ||
        testmsg === "rar";
      if (!extension) {
        this.$message({
          type: "warning",
          message: "文件上传格式不正确！",
        });
        return extension;
      } else if (file.size > maxSize) {
        this.$message.warning(`文件 ${file.name} 大小超过限制（10MB）`);
        return false; // 阻止文件上传
      } else {
        this.uploadFileQT.objectName = "/apply/application/" + file.name;
      }
    },

    // 上传图片之前的函数
    beforeUploadImage(file) {
      // .png, .jpg, .jpeg
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
      if (!extension) {
        this.$message({
          type: "warning",
          message: "图片上传格式不正确！",
        });
        return extension;
      } else {
        this.uploadImage.objectName = "/apply/application/" + file.name;
      }
    },
    // 删除文件
    handleRemoveFile(file, fileList) {
      if (file && file.status === "success") {
        deleteFile({
          objectName: "/apply/application" + file.name,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
    },
    // 删除文件
    handleRemoveFileQT(file, fileList) {
      if (file && file.status === "success") {
        deleteFile({
          objectName: "/apply/application" + file.name,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
    },
    // 文件成功回调
    handleFileSuccess(file, fileList) {},

    //删除图片
    handlePictureRemove(file, fileList) {
      if (file && file.status === "success") {
        deleteFile({
          objectName: "/apply/application" + file.name,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
    },

    // 图片限制
    handlePictureExceed() {
      this.$message({
        message: "最多只能上传十张图片！",
        type: "warning",
      });
    },

    // 图片change
    handlePictureChange(file, fileList) {
      this.ruleForm.pictureName = fileList;
    },

    // 返回上一步
    toUp() {
      this.$emit("updateActive", 0);
    },

    // 提交申请
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let {
            accessoryName,
            pictureName,
            otherNotesFiles,
            auctionTime,
            previewTime,
            ...args
          } = JSON.parse(JSON.stringify(this.ruleForm));
          args.accessoryName = [];
          accessoryName.forEach((item) => {
            args.accessoryName.push("/apply/application/" + item.name);
          });
          args.pictureName = [];
          pictureName.forEach((item) => {
            args.pictureName.push("/apply/application/" + item.name);
          });
          args.otherNotesFiles = [];
          otherNotesFiles.forEach((item) => {
            args.otherNotesFiles.push("/apply/application/" + item.name);
          });
          args.address = args.address.join("-");
          args.auctionStartTime = auctionTime[0];
          args.auctionEndTime = auctionTime[1];
          args.previewStartTime = previewTime[0];
          args.previewEndTime = previewTime[1];
          insertAuction({ ...this.baseInfoData, ...args }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.$router.push({
                path: "/workSpace",
              });
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
          this.$emit("updateActive", 1);
        } else {
          this.$message({
            message: "请填写完整此表单！",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input-group__prepend {
  padding: 0 10px !important;
}
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
/deep/.el-upload-list {
  max-height: 300px;
  overflow: auto;
}
/deep/.el-upload-dragger {
  width: 504px;
}
/deep/.el-form-item__error {
  position: absolute;
  top: 30%;
  left: 60%;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0;
}
.el-form-item__content {
  &:first-child {
    width: 60% !important;
  }
}
.el-upload__tip {
  color: #00000040;
  margin-top: 0;
  line-height: 18px;
  margin-bottom: 28px;
}
.upload-demo {
  width: fit-content;
}
.subjectMatterInfoBox {
  .title {
    font-weight: 500;
    font-size: 22px;
    color: #000000d9;
    text-align: center;
  }
  .formBox {
    margin-top: 30px;
    width: 80%;
    margin-left: 20%;
    padding-bottom: 30px;
    box-sizing: border-box;
    position: relative;
    
    .btnStyle {
      width: 65%;
      display: flex;
      justify-content: flex-end;
      margin-top: 40px !important;
      .btn {
        width: 120px;
        height: 40px;
        background: #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin-left: 20px;
      }
      .up {
        width: 120px;
        height: 40px;
        border: 1px solid #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #0dac65;
        text-align: center;
        line-height: 40px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .bigTitsBox {
      width: 100%;
      height: 40px;
      position: relative;
      .bigTits {
        position: absolute;
        left: -14%;
        width: 100%;
      }
    }

    .tips {
      width: 52.5%;
      font-weight: 400;
      font-size: 12px;
      color: #00000040;
      text-align: justify;
      line-height: 18px;
      margin-left: 140px;
      margin-bottom: 28px;
      margin-top: 8px;
    }
    .tip1 {
      margin-left: 10px;
    }
  }
}
</style>
